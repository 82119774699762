import React, {Component} from 'react';
import {ACCESS_TOKEN} from '../../constants';
import {Redirect} from 'react-router-dom'

class OAuth2RedirectHandler extends Component {

    getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        var results = regex.exec(this.props.location.search);
        let r = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        console.log('Parameter: ' + name + ' value is: ' + r);
        return r;
    };

    render() {
        const token = this.getUrlParameter('token');
        const error = this.getUrlParameter('error');

        console.log('About to render OAuth2RedirectHandler. Token: ' + token + ', error: ' + error);

        // console.log('Token is: ' + token);
        // console.log('Error is: ' + error);

        if (token) {
            console.log('About to store token: ' + token + ' under: ' + ACCESS_TOKEN);
            localStorage.setItem(ACCESS_TOKEN, token);
            return <Redirect to={{
                pathname: "/profile",
                state: {from: this.props.location}
            }}/>;
        } else {
            console.log('About to redirect. Error: ' + error);
            return <Redirect to={{
                pathname: "/login",
                state: {
                    from: this.props.location,
                    error: error
                }
            }}/>;
        }
    }
}

export default OAuth2RedirectHandler;